import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    isLoading: false,
    message: '',
    dataNotificacion: [],
    timeout: 600,
    // api: 'http://localhost:8080',
    // api: 'https://sptest.brighteams.com/api-rest-brighteams',
    api: 'https://sp.brighteams.com/api-rest-brighteams',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_ISLOADING(state, val) {
      state.isLoading = val
    },
    UPDATE_NOTIFICACION(state, val) {
      state.dataNotificacion = []
      state.dataNotificacion = val
    },
    UPDATE_MESSAGE(state, val) {
      state.message = val
    },
    CLEAR_NOTIFICACION(state) {
      state.dataNotificacion = []
    },
  },
  actions: {},
}
