import { canNavigate } from '@/libs/acl/routeProtection'

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    {
      path: '/empresa',
      name: 'empresaVer',
      component: () => import('@/views/Empresa.vue'),
      meta: {
        pageTitle: 'Empresa',
        requiresAuth: true,
        resource: 'empresaVer',
        action: 'empresaVer',
      },
    },
    {
      path: '/cupon',
      name: 'cuponVer',
      component: () => import('@/views/Cupon.vue'),
      meta: {
        pageTitle: 'Canje cupon',
        requiresAuth: true,
        resource: 'cuponVer',
        action: 'cuponVer',
      },
    },
    {
      path: '/notificacion',
      name: 'notificacionVer',
      component: () => import('@/views/Notificacion.vue'),
      meta: {
        pageTitle: 'Notificación',
        requiresAuth: true,
        resource: 'notificacionVer',
        action: 'notificacionVer',
      },
    },
    {
      path: '/carga',
      name: 'cargasVer',
      component: () => import('@/views/Carga.vue'),
      meta: {
        pageTitle: 'Cargas',
        requiresAuth: true,
        resource: 'cargasVer',
        action: 'cargasVer',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboardVer',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        requiresAuth: true,
        resource: 'dashboardVer',
        action: 'dashboardVer',
      },
    },
    {
      path: '/reporte',
      name: 'reporteVer',
      component: () => import('@/views/Reporte.vue'),
      meta: {
        pageTitle: 'Reportes',
        requiresAuth: true,
        resource: 'reporteVer',
        action: 'reporteVer',
      },
    },
    {
      path: '/tienda',
      name: 'tiendaVer',
      component: () => import('@/views/Tienda.vue'),
      meta: {
        pageTitle: 'Tienda',
        requiresAuth: true,
        resource: 'tiendaVer',
        action: 'tiendaVer',
      },
    },
    {
      path: '/rol',
      name: 'rolVer',
      component: () => import('@/views/Rol.vue'),
      meta: {
        pageTitle: 'Rol',
        requiresAuth: true,
        resource: 'rolVer',
        action: 'rolVer',
      },
    },
    {
      path: '/permiso',
      name: 'permisoVer',
      component: () => import('@/views/Permiso.vue'),
      meta: {
        pageTitle: 'Permiso',
        requiresAuth: true,
        resource: 'permisoVer',
        action: 'permisoVer',
      },
    },
    {
      path: '/trivia',
      name: 'triviaVer',
      component: () => import('@/views/Trivia.vue'),
      meta: {
        pageTitle: 'Trivia',
        requiresAuth: true,
        resource: 'triviaVer',
        action: 'triviaVer',
      },
    },
    {
      path: '/documentacion',
      name: 'documentacionVer',
      component: () => import('@/views/Documentacion.vue'),
      meta: {
        pageTitle: 'Documentacion',
        requiresAuth: true,
        resource: 'documentacionVer',
        action: 'documentacionVer',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'no-autorizado',
      component: () => import('@/views/NoAuthorizado.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  store.commit('app/UPDATE_MESSAGE', 'Cargando módulo ...')
  store.commit('app/UPDATE_ISLOADING', true)

  if (to.meta.requiresAuth) {
    const isLoggedIn = localStorage.getItem('userData')
    if (!isLoggedIn) {
      return next({ name: 'login' })
    }
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      // ! We updated login route name here from `auth-login` to `login` in starter-kit
      if (!isLoggedIn) return next({ name: 'login' })

      // If logged in => not authorized
      return next({ name: 'no-autorizado' })
    }

    return next()
  }
  return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
