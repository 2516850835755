<template>
  <div
    v-if="$store.state.app.isLoading"
    class="loading-head"
  >
    <div class="loading-body">
      <div class="loading_effect-1 effects" />
      <div class="loading_effect-2 effects" />
      <div class="loading_effect-3 effects" />
    </div>
    <div
      v-if="$store.state.app.message!=''"
      class="loading-footer text-center h3"
    > {{ $store.state.app.message }} </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>
<style scoped>
.loading-head {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9052;
}
.loading-body {
  position: fixed;
  left: calc(50% - 27.5px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  z-index: 2052;
}
.loading-footer {
  position: fixed;
  top: calc(50% + 60px);
  width: 100%;
  height: auto;
  z-index: 9052;
}
.loading-body .loading_effect-1,
.loading-body .loading_effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #f47404;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-body .loading_effect-1 {
  animation: rotate 1s ease infinite;
}
.loading-body .loading_effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading-body .loading_effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #f47404;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-body .loading_effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
